import React from "react";

export const legal: JSX.Element = (
  <div className="legal ">
    <div className="links">
      <p>
        <a
          className={"brand-midnight hyperlink"}
          href="https://company.ovo.com/privacy-policy/"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy & Cookies
        </a>
      </p>
      <p>
        <a
          className={"brand-midnight hyperlink"}
          href="https://www.ovoenergy.com/help"
          target="_blank"
          rel="noreferrer"
        >
          Help
        </a>
      </p>
    </div>
    <p className={"footer-padding"}>© 2025 OVO Energy Limited</p>
    <p className={"footer-padding"}>
      OVO Energy, 1 Rivergate, Bristol, BS1 6ED
    </p>
  </div>
);
