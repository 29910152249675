import React from "react";
import { logo } from "./logo";

export const headerComponent: (message: string) => JSX.Element = (
  message: string,
) => (
  <div className="header">
    {logo}
    <h4 className="brand-midnight register-interest">{message}</h4>
    <p>
      The Warm Home Discount is a one-off £150 discount given to eligible
      domestic electricity customers to help with their energy costs. <br />
      If you live in <b>England or Wales</b>, you don't need to apply for the
      Warm Home Discount, if you are eligible, you will be{" "}
      <b>contacted by the UK Government</b> between October and January.
      Customers who live in <b>Scotland</b>, that don't qualify for the Core
      Group, can apply for the Warm Home Discount.
    </p>
  </div>
);
