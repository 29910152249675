import { logo } from "../commonComponents/logo";
import React from "react";
import { legal } from "../commonComponents/legal";

const { REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO } = process.env;

export const ClosedApp = () => (
  <div>
    {logo}
    <p className="brand-midnight thank-footer">
      The Broader Group application form for winter 2024/2025 is now closed.{" "}
      <br />
      The form will reopen later in autumn 2025. <br />
      You can find more information about the Warm Home Discount
      <a href={REACT_APP_URL_WARM_HOME_DISCOUNT_SCHEME_INFO} target="_top">
        {" "}
        here.
      </a>
    </p>
    {legal}
  </div>
);
