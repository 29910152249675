import { useMachine } from "@xstate/react";
import { applicationMachine, WhdPreRegState } from "./whd-pre-reg-state";
import {
  DumbPageAccountNotOnSupply,
  DumbPageExitDuplicate,
  DumbPageExitInvalidAccount,
  DumbPageNonScottishPostcode,
  DumbPagePostcodeMismatch,
  DumbPageSuccess,
} from "./dumb-pages";
import { parse } from "query-string";
import get from "lodash/get";
import React, { useEffect, useRef } from "react";
import { FormAccount } from "./formComponents/accountDetailForm";
import { FormCustomerDetails } from "./formComponents/customerDetailForm";
import { StateAction } from "../util/stateUtil";
import { main } from "../commonComponents/main";
import { CustomExitErrorMessage } from "../commonComponents/exitErrorMsg";
import { DumbPageSomethingWentWrong } from "../commonComponents/CommonDumbPages";

const Main = ({ state, stateService }: any) => {
  switch (state.value) {
    case WhdPreRegState.GATHER_ACCOUNT_DETAILS:
      return <FormAccount stateService={stateService} />;

    case WhdPreRegState.GATHER_PERSONAL_DETAILS:
      return <FormCustomerDetails stateService={stateService} />;

    case WhdPreRegState.END_SUCCESS:
      return <DumbPageSuccess />;

    case WhdPreRegState.EXIT_DUPLICATE:
      return <DumbPageExitDuplicate />;

    case WhdPreRegState.EXIT_POSTCODE_MISMATCH:
      return <DumbPagePostcodeMismatch />;

    case WhdPreRegState.EXIT_ACCOUNT_NOT_ON_SUPPLY:
      return <DumbPageExitInvalidAccount />;

    case WhdPreRegState.EXIT_NON_SCOTTISH_POSTCODE:
      return <DumbPageNonScottishPostcode />;

    case WhdPreRegState.EXIT_NOT_ON_SUPPLY:
      return <DumbPageAccountNotOnSupply />;

    case WhdPreRegState.EXIT_MISMATCH:
      return <DumbPagePostcodeMismatch />;

    case WhdPreRegState.EXIT_NOT_FOUND:
      return <DumbPageSomethingWentWrong />;

    case WhdPreRegState.EXIT_ERROR_MESSAGE:
      return <CustomExitErrorMessage stateService={stateService} />;

    default:
      return (
        <div className="spinner-grow" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      );
  }
};

const getParam = (param: string): string => {
  const urlSearchParams = window && parse(get(window, "location.search", {}));
  return get(urlSearchParams, param) as string;
};

export const PreRegApp = () => {
  const [state, send, stateService] = useMachine(applicationMachine);
  const mainRef = useRef(null);

  useEffect(() => {
    if (!(mainRef && mainRef.current)) {
      return;
    }
    if (!(window && window.parent)) {
      return;
    }

    const timeout = setTimeout(() => {
      const parentUrl = getParam("parent-url");
      const clientHeight = (mainRef.current as any).clientHeight;

      window.parent.postMessage(
        {
          func: "scrollTop",
          message: clientHeight,
        },
        parentUrl,
      );
    }, 10);

    return () => clearTimeout(timeout);
  }, [state.value]);

  useEffect(() => {
    const stage = getParam("journey-entry");

    if (
      stage ===
      WhdPreRegState.GATHER_PERSONAL_DETAILS.toLowerCase().replace(/_/g, "-")
    ) {
      send(StateAction.JUMP);
    }
  }, [send]);

  const mainDiv = <Main state={state} stateService={stateService} />;
  return main(
    mainDiv,
    mainRef,
    "Register your interest for the scheme 2025-2026",
  );
};
